.right {
    float: right;
}
.left {
    float: left;
}
.red_text {
    color: #a00;
}
.blue_text {
    color: #00f !important;
}
.green_text {
    color: #090;
}
.hideme {
    display: none !important;
}
.bold {
    font-weight: bold;
}

html > body .clear_fix, html > body .clearfix {
    zoom: 1;
}
.clear_fix:after, .clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

body {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 11px;
    text-align: left;
}

table {
    table-layout: auto;
}

td {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 11px;
}

th {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 11px;
}

#content-container th {
    font-weight: normal;
    background-color: #acacac;
    color: #fff;
    text-align: left;
}

th.c {
    text-align: center;
}

ul {
    padding: 0;
    margin: 0 0 0 15px;
    list-style-image: url(/media/images/bullet.gif);
}

li {
    padding: 2px;
}

/* Typography */
.cap {
    text-transform: capitalize;
}

.alt-row-white {
    background-color: #fff;
    color: #000;
}
.alt-row-light {
    background-color: #f0f0f0;
    color: #666;
}

.alt-row-dark {
    background-color: #ddd;
    color: #000;
}

.alt-row-darkred {
    background-color: #a1000b;
    color: white;
}
.alt-row-red {
    background-color: white;
    color: red;
    border: 1px solid red;
}

.alt-row-green {
    background-color: white;
    color: green;
    border: 1px solid green;
}

#topRightNav {
    padding: 6px;
    font: bold 10px verdana;
}

#topRightLine {
    background-color: #eee;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 4px;
}

#mainNav {
    background-color: #737373;
    width: 750px;
    height: 23px;
}

#titlehelp {
    margin-left: 13px;
    margin-top: 12px;
    margin-bottom: 2px;
    height: 30px;
    width: 735px;
}

#title {
    margin-left: 10px;
    margin-top: 15px;
    font: bold 15px tahoma;
    letter-spacing: 1px;
    color: #737373;
}

#title2 {
    float: left;
    font: bold 15px tahoma;
    color: #737373;
    letter-spacing: 1px;
}

#helpicon {
    float: right;
    margin: -7px 0 0;
}

#date {
    margin-left: 10px;
    margin-top: 2px;
    margin-bottom: 10px;
    font: 12px arial;
    color: #737373;
}

/*noinspection CssOverwrittenProperties*/
#leftContent {
    float: left;
    width: 200px;
    margin-right: 15px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 200px;
}

html > body #leftContent {
    width: 200px; /* ie5win fudge ends */
}

/*noinspection CssOverwrittenProperties*/
#mainContent {
    float: left;
    width: 532px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 532px;
}

html > body #mainContent {
    width: 532px; /* ie5win fudge ends */
}

/*noinspection CssOverwrittenProperties*/
#mainContentWide {
    float: left;
    width: 750px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 750px;
}

html > body #mainContentWide {
    width: 750px; /* ie5win fudge ends */
}

/*noinspection CssOverwrittenProperties*/
#mainContentWider {
    float: left;
    width: 1200px;
    voice-family: "\"}\"";
    voice-family: inherit;
}

html > body #mainContentWider {
    width: 1200px; /* ie5win fudge ends */
}

/* Added for Ad Placements*/
/*noinspection CssOverwrittenProperties*/
#mainContentAds {
    float: left;
    width: 384px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 384px;
}

html > body #mainContentAds {
    width: 384px; /* ie5win fudge ends */
}

/*noinspection CssOverwrittenProperties*/
#rightContent {
    float: left;
    width: 140px;
    margin-left: 11px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 140px;
}

html > body #rightContent {
    width: 140px; /* ie5win fudge ends */
}

.box-top, .box-bottom {
    background-color: #737373;
    padding: 4px 4px 4px 8px;
    font-size: 11px;
    color: #fff;
    margin-bottom: 1px;
}

.box-top-error {
    background-color: #c00;
    padding: 4px 4px 4px 8px;
    font-size: 11px;
    color: #fff;
    margin-bottom: 1px;
}

.box-top-light {
    background-color: #f2f2f2;
    padding: 4px 4px 4px 8px;
    font-size: 11px;
    border: 1px solid #aaa;
    color: #333;
    margin: 0;
}

.box-top-blue {
    background-color: #062aa9;
    padding: 4px 4px 4px 8px;
    font-size: 11px;
    color: #fff;
    margin-bottom: 1px;
}

.box-subtop {
    padding: 4px 4px 4px 0;
    font-size: 11px;
    color: #333;
    border-bottom: 1px solid #737373;
    margin: 10px;
}

.box-contents {
    background-color: #f8f8f8;
    border: 1px solid #737373;
    padding: 10px;
    font-size: 11px;
    color: #666;
}

.box-contents-light {
    background-color: #fff;
    border: 1px solid #aaa;
    border-top: 0;
    padding: 10px;
    font-size: 11px;
    margin-bottom: 10px;
}
.box-contents-white {
    background-color: #fff;
    border: 1px solid #737373;
    padding: 10px;
    font: 11px verdana;
    margin-bottom: 10px;
}

.box-contents-error {
    background-color: #fff;
    border: 1px solid #c00;
    padding: 10px;
    font-size: 11px;
    margin-bottom: 10px;
}

.box-contents-blue {
    background-color: #fff;
    border: 1px solid #062aa9;
    padding: 10px;
    font-size: 11px;
    margin-bottom: 10px;
}

.box-contents-table {
    background-color: #fff;
    border: 1px solid #737373;
    font-size: 11px;
    margin-bottom: 10px;
}

#spacer {
    clear: both;
    height: 2px;
}

/*noinspection CssOverwrittenProperties*/
#footer {
    width: 750px;
    margin-top: 10px;
    voice-family: "\"}\"";
    voice-family: inherit;
    width: 750px;
}

html > body #footer {
    width: 750px; /* ie5win fudge ends */
}

div.formContainer {
    padding-bottom: 25px;
}

div.formRow {
    clear: both;
    padding: 3px;
}

div.formRow span.formLabel {
    float: left;
    text-align: right;
    padding-top: 7px;
    padding-right: 5px;
}
div.formRow span.formLabelWide {
    float: left;
    text-align: right;
    padding-top: 7px;
    padding-right: 5px;
    width: 125px;
}
div.formRow span.formTitle {
    float: left;
    text-align: right;
    padding: 5px 10px 0 0;
    font-weight: bold;
}
div.formRow span.formLabelTextLeft {
    text-align: left;
    width: 300px;
    overflow-wrap: break-word;
}
div.formRow span.formLabelLarge {
    float: left;
    text-align: right;
    padding: 25px 20px 0 0;
    font-size: 12px;
}
div.formRow span.errorLabel {
    float: left;
    text-align: left;
    padding-top: 7px;
    padding-left: 5px;
    color: #c00;
}

div.formRow span.formw, div.formRow span.formNoField, div.formRow span.formButton {
    float: left;
    text-align: left;
    padding-top: 5px;
}

div.formRow span.formNoField {
    padding-top: 7px;
}

div.formRow span.formButton {
    padding-top: 3px;
}

div.displayContainer {
    padding-bottom: 25px;
}

div.displayRow {
    clear: both;
    padding: 3px;
}

div.displayRow span.displayLabel {
    float: left;
    text-align: right;
    padding-right: 5px;
}

div.displayRow span.displayValue {
    float: left;
    text-align: left;
}
div.anncFader {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 11px;
    border-style: none;
}

div.annc_list_box, div.annc_list_box_fixed_width, div.annc_list_box_fixed_width_narrow {
    height: 100px;
    overflow: auto;
}

div.annc_list_box_fixed_width {
    width: 500px;
}
div.annc_list_box_fixed_width_narrow {
    width: 350px;
}

input.textbox {
    background-color: #f5f5f5;
    border: 1px solid #737373;
    width: 100px;
    height: 16px;
    font: 11px verdana;
}

.textbox-sm, .textbox-md, .textbox-lg, .textbox-xl, .textbox-xxl, .textbox-full {
    background-color: #f5f5f5;
    border: 1px solid #737373;
    height: 16px;
    font: 11px verdana;
}

.textbox-sm {
    width: 40px;
}

.textbox-md {
    width: 130px;
}

.textbox-lg {
    width: 175px;
}

.textbox-xl {
    width: 300px;
}

.textbox-xxl {
    width: 500px;
}

.textbox-full {
    width: auto;
}

.textbox-clear {
    background-color: transparent;
    border: 0;
    height: 16px;
    font: 11px verdana;
}

.textbox-file {
    height: 22px;
    font: 10px verdana;
    border: 1px solid #737373;
    background-color: #f5f5f5;
}

.smButton {
    height: 20px;
    font: 11px arial;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid #737373;
    background-color: #e3e3e3;
}

.smButton:hover {
    height: 20px;
    font: 11px arial;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid #737373;
    background-color: #ececec;
    color: #333;
}

.smButton:disabled {
    border: 1px solid #b9b9b9;
    background-color: #f1f1f1;
    color: #999;
}

SELECT {
    background-color: #f5f5f5;
    border: 1px solid #737373;
    font: 11px verdana;
}

#forgotPassword {

}

#forgotPasswordForm {
    display: none;
}

.smText {
    font: 9px verdana;
    color: #666;
}

/* Page through query results */
#pageresults {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 750px;;
}
#pageresults ul {
    border: 0;
    margin: 0;
    padding: 0;
}
#pagination li {
    border: 0;
    padding: 0;
    font-size: 11px;
    list-style: none;
    margin: 0 2px 0 0;
    font-family: arial, sans-serif;
}
#pagination a {
    border: solid 1px #999;
    margin-right: 2px;
}
#pagination .previous-off,
#pagination .next-off {
    color: #888;
    display: block;
    float: left;
    margin-right: 2px;
    padding: 3px 4px;
}
#pagination .next a,
#pagination .previous a {
}
#pagination .active {
    background: #aaa;
    color: #fff;
    display: block;
    float: left;
    padding: 4px 6px;
}
#pagination a:link,
#pagination a:visited {
    color: #999;
    display: block;
    float: left;
    padding: 3px 6px;
    text-decoration: none;
}
#pagination a:hover {
    border: solid 1px #666;
}

.note_cont {
    padding: 0;
    border: 1px solid #ccc;
    font-size: 10px;
    margin: 0 0 5px 0;
}
.note_bar {
    padding: 3px;
    background: #e1e1e1;
    border-bottom: 1px solid #ccc;
    font-size: 10px;
    margin: 0 0 5px 0;
}
.note_text {
    padding: 5px;
}

table.cfdebug {
    clear: both;
    margin-top: 1000px;
}
.line_item {
    margin: 3px 3px 0 3px;
    padding: 0 0 3px 0;
    font-size: 10px;
    border-bottom: 1px dotted #ccc;
}

/*INTERIOR STYLES*/
.filter_container {
    padding: 10px;
    margin: 0;
    background: #f1f1f1;
    border: 1px solid #ccc;
}
.filter_container form, .filter_container input {
    margin: 0;
}
.filter_container hr {
    background: none repeat scroll 0 0 #ccc;
    margin: 10px auto;
    padding: 0;
}
.filter_container label {
    padding-right: 5px;
}
.filter_container select {
    background: #fff;
}

#help_header a {
    float: left;
    padding: 5px;
    border-right: 1px solid #aaa;
    color: blue;
    text-decoration: none;
}
#help_header a:hover {
    background: #ccc;
}
#help_header a.current {
    color: white;
    background: #036;
}
.search_box {
    float: right;
    margin: 0 10px 0 0;
    padding: 0;
}
.search_box form {
    margin: 0;
    padding: 0;
}
a.exp_sub {
    text-decoration: none;
}
tr.parent_tr td {
    border-bottom: 1px solid #aaa;
}
/* PAGINATION LINKS */
.pagination_top {
    background: url(/media/images/f1_roundtab.gif) no-repeat 0 0;
    padding: 3px 0 3px 8px;
    margin: 0;
}
.pagination_top span.top_tab {
    padding: 0 40px 0 8px;
    font-style: italic;
}
.pagination_top span.top_sep {
    padding: 0 10px;
    color: #f1f1f1;
}
.pagination_container {
    margin: 0 0 5px 0;
    padding: 5px;
    text-align: center;
    background: #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
.pagination_container a {
    float: left;
    padding: 2px 5px;
    margin: 0;
    background: #e7e7e7;
    border: 1px solid;
    border-color: #fff #ccc #aaa #fff;
    text-decoration: none;
    color: #333 !important;
}
.pagination_container a:hover {
    color: #06c !important;
    border: 1px solid #06c;
}
.pagination_container a.cur {
    color: #06c !important;
    border: 1px solid #06c;
    font-style: italic;
    background: #fff;
}
.pagination_container a.prev, .pagination_container a.next, .pagination_container a.first, .pagination_container a.last {
    background: #ccc;
    border: 1px solid;
    border-color: #fff #aaa #aaa #fff;
    text-decoration: none;
    color: #444;
}
.pagination_container a.prev:hover, .pagination_container a.next:hover, .pagination_container a.first:hover, .pagination_container a.last:hover {
    color: #06c;
    border: 1px solid;
}
div.pagination_container a.first {
    border-color: #fff #aaa #aaa #aaa;
}
.pagination_container .pagelink_container {
    float: left;
    max-width: 416px;
    margin: 0;
}
.pagination_container .pagelink_container a {
    width: 30px;
    text-align: center;
    padding: 2px 0;
}


.slide_container {
    display: none;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    background: #f4f4f4;
}
/*noinspection CssInvalidHtmlTagReference*/
.slide_container input, .slide_container checkbox, .slide_container radio, .slide_container select {
    background: white;
}
.card_action_container {
    background: #f2f2f2;
    padding: 3px;
    border: 1px solid #ccc;
    border-right-width: 0;
    border-left-width: 0;
    text-align: center;
    font-size: 8px;
}
.tabbed_content {
    display: none;
}
.display_tabs_container {
    padding: 0;
    margin: 0;
    height: 30px;
}
.display_tabs_container a {
    overflow: hidden;
    background: #ccc;
    color: black;
    float: left;
    width: 121px;
    border-style: solid;
    border-color: #fff #999 #999 #fff;
    border-width: 1px;
    padding: 3px 5px;
    text-align: center;
    text-decoration: none;
}
.display_tabs_container a:hover {
    background: #ddd;
    color: black;
}
.display_tabs_container a.curr {
    background: #ddd;
    color: #9a6312;
    font-style: italic;
}
.display_tabs_container a:active {
    outline: none;
}

/*noinspection CssUnknownProperty*/
.display_tabs_container a:focus {
    -moz-outline-style: none;
}
:-moz-any-link:focus {
    outline: none;
}

/* paystub table */
table.paystub_table {
    margin: 0;
    padding: 0;
}
table.paystub_table td {
    padding: 3px;
    font-size: 10px;
}
table.paystub_table td.bottom_border {
    border-bottom: 2px solid #999;
}
table.paystub_table td.thin_bottom_border {
    border-bottom: 1px solid #f0f0f0;
}
table.paystub_table td.top_border {
    border-top: 2px solid #999;
}
table.paystub_table td.grey_bg {
    background: #f0f0f0;
}
table.paystub_table td.border_fill {
    background: #999;
    padding: 0;
    height: 1px;
}

table.paystub_table td.numeric_container {
    text-align: right !important;
}
table.paystub_table th {
    text-align: right;
    background-color: white;
    border-bottom: 3px solid #999;
    color: #000;
}

.tbl_billpay th {
    color: white !important;
    text-align: center;
}
.tbl_billpay th a {
    color: white !important;
    text-decoration: none;
    text-align: center;
}
.tbl_billpay th a:hover {
    text-decoration: underline;
}
.tbl_billpay td {
    text-align: right;
}
.tbl_billpay td a {
    text-decoration: none;
    text-transform: capitalize;
    color: blue !important;
}
.tbl_billpay td a.err {
    color: #c33 !important;
    text-transform: capitalize;
}
.quick_search a {
    padding: 5px 10px;
    color: blue !important;
    text-decoration: none;
    border: 1px solid #f1f1f1;
}
.quick_search a.curr, .quick_search a:hover {
    color: #09f !important;
    text-decoration: none;
    border: 1px solid #09f !important;
    background: #f1f1f1 !important;
}

a.grey_block_link, .grey_block_links > a {
    display: block;
    padding: 3px;
    text-decoration: none;
}
a.grey_block_link:hover, .grey_block_links > a:hover {
    background-color: #e1e1e1;
    color: #333 !important;
}

#leftContent .grey_block_links > a {
    font-size: 10px;
    text-transform: capitalize;
}

.customerTable a.id_link {
    color: white !important;
    text-align: center;
    width: 100%;
    height: 100%;
    text-decoration: none !important;
    display: block;
}
.customerTable .id_cont {
    background: #888 !important;
    color: white !important;
    text-align: center;
}
.customerTable .id_cont:hover {
    background: #aaa !important;
    color: white !important;
    text-align: center;
}

table.fees_popup td {
    padding: 10px !important;
    border-bottom: 2px dotted #e6e6e6 !important;
}
table#fulfillment td {
    vertical-align: top;
}
table#fulfillment td a {
    color: #09c;
    text-decoration: none;
}

.yel_arrow {
    padding-left: 15px;
    background: url(/media/images/bullet-orange-right.gif) no-repeat 0 50%;
}
.yel_arrow_block {
    padding: 3px 0 3px 15px;
    background: url(/media/images/bullet-orange-right.gif) no-repeat 0 50%;
    display: block;
    margin-bottom: 3px;
}
.white_arrow {
    padding-left: 15px;
    background: url(/media/images/bullet-white-right.gif) no-repeat 0 50%;
}

.sec_head {
    padding: 5px 10px;
    background: #d3d3d3;
    margin: 0 0 5px 0;
    color: #000;
}
.sec_head_drk {
    padding: 5px 10px;
    background: #737373;
    margin: 0 0 5px 0;
    color: #fff;
}
h4.sec_head {
    font-weight: lighter;
}
#main .boxed_title a, #main .sec_head_drk a {
    color: #fc0;
    text-decoration: none;
    font-weight: normal;
}
#main .boxed_title a:hover, #main .sec_head_drk a:hover {
    color: #ccc;
}
#main .boxed_container a {
    text-decoration: none;
}
#main .boxed_container a:hover {
    text-decoration: none;
    color: #c88b34;
}
.boxed_title {
    padding: 5px 10px;
    background: url(/media/images/gradient_drk_left.gif) no-repeat 0 0 #737373;
    margin: 0;
    position: absolute;
    color: white;
    z-index: 999 !important;
}
.boxed_container, .boxed_button_menu {
    padding: 25px 10px 10px 10px;
    margin: 13px 0 10px 0;
    background: url(/media/images/greyCorner.gif) no-repeat 0 0 #f1f1f1;
    z-index: 1;
    border: 1px solid #bfbfbf;
}
.boxed_button_menu {
    padding: 10px;
}
.boxed_container input, .boxed_container select, .boxed_container textarea {
    background-color: white !important;
    border: 1px solid #bbb;
}
.boxed_container input.smButton, .clean_form input.smButton {
    background-color: #737373 !important;
    border: 1px solid #fff;
    color: #fff;
    padding: 1px 5px;
    height: auto;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    font-size: 12px;
}
.boxed_container input.smButton:hover, .clean_form input.smButton:hover {
    background-color: #fff !important;
    border: 1px solid #c88b34;
    color: #000;
}
.boxed_container input.smButton:disabled, .clean_form input.smButton:disabled {
    background-color: #b9b9b9 !important;
    border: 1px solid #fff;
    color: #fff;
}

.boxed_title_red {
    padding: 5px 10px;
    background: url(/media/images/drk_red.gif) repeat-x 0 0 #c25656;
    margin: 0 0 5px 0;
    position: absolute;
    color: white;
    z-index: 999 !important;
}
.boxed_container_red {
    padding: 25px 10px 10px 10px;
    margin: 13px 0 10px 0;
    background: url(/media/images/greyCorner.gif) no-repeat 0 0 #f1f1f1;
    z-index: 1;
    border: 1px solid #930;
}
.grey_shadow {
    width: 100%;
    height: 12px;
    background: url(/media/images/grey_grad.png) repeat-x;
}
.boxed_title .grey_shadow, .boxed_title_red .grey_shadow {
    margin: 4px 0 0 -10px;
    position: absolute;
    border-top: 1px solid #545454;
}

.table_container {
    padding: 10px 0;
}
#main .table_container table {
    background-color: #fff;
}
#main .table_container table td {
    background-color: #f1f1f1 !important;
    border-bottom: 1px solid #fff;
}
#main .table_container table td a {
    text-decoration: none;
}
#main .table_container table td.alt-row-white {
    background-color: #fff !important;
}

div.boxed_table_container {
    padding: 20px 3px 3px;
    background: #dfdfdf;
}
div.boxed_table_container_hd {
    padding: 0;
    margin-top: 28px;
    background: #aaa;
}

table.boxed_table {
}
div.boxed_table_container_hd table.boxed_table {
}

#main table.boxed_table td {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid white;
    padding: 5px;
    background: #efefef;
}
#main table.boxed_table tr.no-border td {
    border-bottom: 0 !important;
}
#main table.boxed_table tr:hover td, #main table.boxed_table tr:hover td a.id_link {
}
#main table.boxed_table td a, #main .boxed_container a {
    color: #06c;
    text-decoration: none;
}
#main table.boxed_table td a:hover {
    color: #333;
    text-decoration: underline;
}
#main table.boxed_table td.lbl {
    background: #dfdfdf;
    border-right: 1px solid #ccc;
}
#main table.boxed_table td.alt-row-darkred {
    background: url(/media/images/drk_red.gif) repeat-x bottom left #bf0808;
    color: white;
    font-weight: bold;
}
#main table.boxed_table th, #main table.boxed_table caption {
    font-weight: normal;
    padding: 5px;
    background: #aaa url(/media/images/gray_fade.png) repeat-x left top;
    border-right: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    color: #fff;
}
.formSep {
    width: 98%;
    margin: 10px 0;
}
.geo_table {
    border-top: 1px solid #ccc;
}
.geo_table td {
    text-align: left;
    padding: 0 10px 0 0;
    vertical-align: top;
}
.geo_table th {
    text-align: left;
    background: #f3f3f3;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.boxed_table td.lbl {
    width: 2%;
}

table td.main_linkbox, #main table.boxed_table td.main_linkbox {
    width: 160px;
    vertical-align: top;
    padding: 10px;
    background: url(/media/images/frosty_left.png) repeat-y -90px 0 #686868;
    color: #e1e1e1;
    border: 1px solid #000;
}
table td.main_formbox, #main table.boxed_table td.main_formbox {
    width: 320px;
    vertical-align: top;
    padding: 10px;
    background: #f1f1f1;
    color: #333;
    border: 1px solid #a1a1a1;
}

.main_linkbox {
    padding: 10px 10px 10px 15px;
    background: url(/media/images/frosty_left.png) repeat-y -90px 0 #686868;
    color: white;
    border: 2px solid #444;
}
.main_linkbox input {
    border: 1px solid black;
    padding: 2px 5px;
}

#main table td.main_linkbox a {
    text-decoration: none;
    color: white;
}
#main table td.main_linkbox a:hover {
    text-decoration: none;
    color: yellow;
    background: #777;
}

.topBar {
    width: 100%;
    color: #ccc;
    background: #656565 url(/media/images/grey_trans_up.png) bottom left repeat-x;
    border-bottom: 1px solid #aaa;
    padding: 5px 0;
}

.web_print_link {
    background: url(/media/images/printer_30.png) top left no-repeat;
    display: block;
    height: 30px;
    width: 100px;
    padding: 8px 0 0 35px;
    vertical-align: middle;
    text-decoration: none;
    color: blue !important;
}

.input_maxwidth {
    max-width: 200px;
}

/* Edit Cardholder */
.email-box {
    border: 1px solid #ccc;
    width: 410px;
    padding: 8px 10px;
    margin: 0 0 0 105px;
}
.email-box p {
    margin: 0 0 1em 0;
}

#email-verification-status-wrapper, #email-options {
    margin-top: 12px;
}
.inline-wrapper {
    display: inline;
}

table.boxed_table td.grey_td {
    background: #dfdfdf !important;
    color: #000;
}

table.boxed_table td.green_td {
    background: #060 !important;
    color: #fff;
}

table.boxed_table td.blue_td {
    background: #069 !important;
    color: #fff;
}

table.boxed_table td.red_td {
    background: #930 !important;
    color: #fff;
}
.dis, .ign, .com {
    display: none;
    padding: 10px;
    color: black;
}
.dis hr, .ign hr {
    margin: 10px 5px !important;
}
.tbox {
    padding: 5px !important;
    width: 200px !important;
}
.mbox {
    margin: 2px 0;
    border: 1px solid #aaa;
    padding: 3px;
    text-align: center;
    font-weight: bold;
}

td.dps_td {
    background: url(/media/images/dps_sm_logo.gif) no-repeat 50% 50% #efefef;
    width: 30px;
}
td.elan_td {
    background: url(/media/images/elan_sm_logo.gif) no-repeat 50% 50% #efefef;
    width: 30px;
}
td.ips_td {
    background: url(/media/images/ips_sm_logo.gif) no-repeat 50% 50% #efefef;
    width: 30px;
}
td.star_td {
    background: url(/media/images/star_sm_logo.gif) no-repeat 50% 50% #efefef;
    width: 30px;
}

#main #masterContainer a.pop_card {
    color: #000;
}
#main #masterContainer a.pop_card:hover {
    color: #09c;
    text-decoration: underline;
}

#main #masterContainer a.pop_card_white {
    color: #fff;
}
#main #masterContainer a.pop_card_white:hover {
    color: #c88b34;
    text-decoration: underline;
}

.pop_card_text {
    font-size: 16px;
    font-weight: bold;
}

.merged-divs {
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.pagination {
    margin: 4px 0 0;
    text-align: left;
}
.pagination-links {
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
}
.pagination-links .pagination-wrapper {
    display: block;
    overflow: hidden;
}
.pagination-links a, .pagination-links b {
    background: #eee repeat-x 0 -178px;
    border: 1px solid #aaa;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 12px #fff inset, 0 1px 2px #bbb;
    color: #333;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    margin: 0 2px 2px;
    overflow: hidden;
    padding: 4px 8px;
    position: relative;
    text-decoration: none;
    text-shadow: 0 1px #fff;
    vertical-align: middle;
}

.pagination-links a, .pagination-links b {
    border-left-width: 0;
    border-radius: 0 0 0 0;
    box-shadow: 0 11px #fff inset, 0 1px 2px #bbb;
    display: inline-block;
    float: left;
    font-size: 11px;
    margin: 0 0 2px;
}

.pagination-links a:first-child, .pagination-links b:first-child {
    border-left-width: 1px;
    border-radius: 3px 0 0 3px;
    margin-left: 2px;
}
.pagination-links a:last-child, .pagination-links b:last-child {
    border-radius: 0 3px 3px 0;
}
.pagination-links .active-button {
    padding-bottom: 4px;
    padding-top: 4px;
}
.pagination-links a:active, .pagination-links .active-button {
    box-shadow: 0 1px 3px #bbb inset, 0 1px #fff;
    padding: 5px 8px 3px;
}

.nav_network {
    background: #1a1a1a;
    color: #fff;
    padding: 5px 0;
    width: 60px;
    text-align: center;
}
.passcode_label {
    float: left;
    background: red;
    color: white;
    padding: 5px 10px;
    margin-right: 10px;
}
.passcode_text {
    float: left;
    color: #fff;
    padding: 5px 10px;
}

#phoneQ_container {

    position: absolute;
    right: 10px;
    margin-top: 10px;
    padding: 5px;
    color: #656556;
    width: auto;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 1px 0 8px #f1f1f1 inset, 0 1px #fff;


}

.pm_container a.on_button {
    box-shadow: 0 1px 3px #545454 inset, 0 1px #fff;
    background: #519ad6;
    color: #fff;
    border-color: #545454;
}
.pm_container a.off_button {
    color: #fff;
    background: #9f9f9f;
    border-color: #545454;
    box-shadow: 0 1px 3px #545454 inset, 0 1px #fff;
    border-left: 1px solid #777;
}

.pm_label {
    float: right;
    font-weight: bold;
    height: 100%;
    line-height: 12px;
    margin-left: 10px;
    padding: 3px 0;
    text-align: center;
    width: 100px;
}


.pm_container {
    float: right;
    margin: 2px;
}
.pm_container a {
    float: left;
    width: 40px;
    text-align: center;
    height: 100%;
    font-weight: normal;
    padding: 5px 0;
    text-decoration: none;
    border: 1px solid #bbb;
}
.pm_container .pm_input {
    float: left;
    background: #f1f1f1;
    width: 50px;
    text-align: center;
    height: 100%;
    font-weight: bold;
    padding: 5px 0;
    color: #333;
    text-decoration: none;
    border: 1px solid #bbb;
}
.pm_container .pm_input_sbm {
    float: left;
    width: 40px;
    text-align: center;
    height: 26px;
    border: 1px solid #bbb;
}

a.pm_position_on, .pm_container .pm_input {
    border-radius: 5px 0 0 5px;
    border-right: #333;
}
.pm_container .pm_input {
    box-shadow: 1px 0 8px #999 inset, 0 1px #fff;
}
a.pm_position_off, .pm_container .pm_input_sbm {
    border-radius: 0 5px 5px 0;
    border-left: 1px solid #dedede;
}
.pm_container .pm_release {
    float: left;
    border-radius: 5px;
    width: 130px;
    text-align: center;
    height: 28px;
    font-weight: normal;
    font-size: 12px;
    border: 1px solid #bbb;
    margin-left: 10px;
}
.pm_container .pm_release:hover, .pm_container .pm_input_sbm:hover {
    cursor: pointer;
    color: #09c;
}

#phoneQ_incoming {
}
.pm_user {
    border: 1px solid #545454;
    border-radius: 5px 5px 5px 5px;
    float: left;
    font-weight: normal;
    height: 20px;
    padding: 3px 10px;
    width: auto;
    margin-left: 10px;
    line-height: 21px;
    color: #fff;
    background: #9f9f9f;

    box-shadow: 0 1px 3px #545454 inset, 0 1px #fff;

}
#phoneQ_incoming a.pm_incoming_action {
    float: left;
    width: 65px;
    border-radius: 5px;
    text-align: center;
    line-height: 20px;
    font-weight: normal;
    padding: 3px 0;
    text-decoration: none;
    border: 1px solid #bbb;
}


.gradient_btn_lite {
    color: #454545;
    background: rgb(255, 255, 255); /* Old browsers */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2RhZGFkYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNmY2ZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 50%, rgba(218, 218, 218, 1) 51%, rgba(246, 246, 246, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(241, 241, 241, 1)), color-stop(51%, rgba(218, 218, 218, 1)), color-stop(100%, rgba(246, 246, 246, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 50%, rgba(218, 218, 218, 1) 51%, rgba(246, 246, 246, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 50%, rgba(218, 218, 218, 1) 51%, rgba(246, 246, 246, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 50%, rgba(218, 218, 218, 1) 51%, rgba(246, 246, 246, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 50%, rgba(218, 218, 218, 1) 51%, rgba(246, 246, 246, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6', GradientType=0); /* IE6-8 */
}
#progressbar .ui-progressbar-value {
    background-color: #519ad6;
}
/* cardholder calculators */
#mc_container {
    min-height: 450px;
}
.selectedCal {
    background-color: #dfdfdf;
}
.commonCal {
    display: none;
    width: 100%;
}
.link_bars_container {
    list-style: none none;
    margin: 0;
}
.link_bars_container li {
    border-bottom: 1px solid #ccc;
}
.link_bars_container li.last_bar {
    border-bottom: 1px solid #fff;
}
.link_bars_container li a {
    display: block;
    text-decoration: none;
    padding: 5px;
}
.link_bars_container li:hover {
    background-color: #f2f2f2;
}

/*INTERNAL CIP*/
div.cip_popup, div#cip_popup, div#cip_popup2 {
    background: #eaeaea;
    border: 1px solid #aaa;
    width: 800px;
    margin: 0;
}
div.cip_popup .leftContainer {
    float: left;
    margin: 5px 10px 5px 5px;
    width: 575px;
}
div.cip_popup .rightContainer {
    float: left;
    margin: 5px 10px 5px 5px;
    width: 165px;
}
div.cip_popup .leftContent {
    border: 1px solid #ccc;
    text-align: center;
    background: white;
    height: 450px;
    width: 575px;
}
div.cip_popup .leftContainer #doc_img {
    max-width: 550px;
    text-align: center;
    cursor: pointer;
}
div.cip_popup .leftContainer a {
    text-align: center;
    color: #06c;
    text-decoration: none;
    display: block;
    margin: 0 auto;
}
div.cip_popup .leftContainer a:hover {
    color: #060;
}

.fieldContainer {
    padding: 10px 5px 20px;
    margin: 10px 0;
    border-bottom: 1px solid #ccc;
}
.cip_link_viewer {
    margin: 20px 0;
}
.cip_img_viewer {
}
div.ui-dialog {
    padding: 0;
    border: 0;
    box-shadow: 0 0 55px #333;
    z-index: 9999;
    position: fixed;
}
div.ui-dialog-titlebar {
    border-radius: 0;
    border-bottom: 0;
}
table#cip_docs_tbl td.main_linkbox a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;
}
.the_notes {
    font-size: 10px;
    margin-bottom: 10px;
}
a.uploadToggleLink {
    display: block;
    padding: 5px;
    background: #f3f3f3;
    margin-top: 5px;
    border: 1px solid #ccc;
}
a.uploadToggleLink:hover {
    background: #f8f8f8;
}
.uploadFormDrop {
    background: #f3f3f3;
    padding: 10px;
    display: none;
    border: 1px solid #ccc;
    border-top: 1px dashed #ccc;
}
.zoomContainer {
    z-index: 99999 !important;
}

#screen_mask {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: white;
    opacity: .95;
}
.screenCenter {
    border: 1px solid #ccc;
    width: 80%;
    margin: 20% auto 0;
    background: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
}
.screenCenter h2 {
    background: url("/media/images/warning.png") no-repeat scroll left top rgba(0, 0, 0, 0);
    border-bottom: 1px solid #dedede;
    min-height: 48px;
    line-height: 40px;
    padding-left: 60px;
}
.screenCenter h4 {
    background: url("/media/images/warning.png") no-repeat scroll left top rgba(0, 0, 0, 0);
    border-bottom: 1px solid #dedede;
    min-height: 48px;
    line-height: 1.2;
    padding: 0 0 10px 60px;
}

#screenSaver {
    display: none;
    border: 1px solid #aaa;
    font-size: 12px;
}
.no_close_btn .ui-dialog-titlebar-close {
    display: none !important;
}
.screenSaver_title {
    padding: 10px;
}
.screenSaver_counter {
    color: #930;
    font-weight: bold;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    height: 50px;
    border: 1px solid #ccc;
    background: #f3f3f3;
}
#countdownContainer {
    text-align: center;
}
.minutes, .seconds, .sep {
    font-family: "Courier New", Courier, monospace;
    font-size: 16px;
}
#screenSaver a.blu_button {
    background: none repeat scroll 0 0 #f3f3f3;
    border: 1px solid #09c;
    color: #09c;
    display: block;
    margin: 5px auto;
    padding: 5px 10px;
    text-decoration: none;
    width: 170px;
    text-align: center;
}


/*GLOBAL BOOTSTRAP */

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #d0d0d0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
    padding: 24px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.well-small {
    padding: 9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.well form, .well-drk form {
    margin-bottom: 0;
}
.well-drk {
    background: #f0f0f0 !important;
}
.well-white {
    background: #fff !important;
}
hr {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #dedede;
    height: 1px;
}
.annc_icon {
    color: #aaa;
    float: left;
    font-size: 20px;
    margin: 12px 10px 12px 0;
}
.annc_urgent {
    color: #c00;
}

#map_canvas {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.pop_container {
    width: 200px;
    height: auto;
    margin: 0;
}
.pop_container .pop_title {
    display: block;
    margin-bottom: 3px;
    font-weight: bold !important;
    text-align: center;
}
.pop_container .pop_address {
    display: block;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px dashed #ccc;
    font-size: 12px !important;
    text-align: center;
}
.pop_container a {
    float: left;
    width: 45%;
    margin: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    padding: 5px 0;
    font-size: 12px;
}

#atmlist {
    margin: 0;
    list-style: none;
}
#atmlist .pop_title {
    display: block;
    margin-bottom: 3px;
    font-weight: bold !important;
    text-align: left;
}
#atmlist .pop_address {
    display: block;
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-size: 12px !important;
}
#atmlist li a {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}
.gm-style img {
    max-width: none !important;
}
li img.marker_li {
    float: left;
    margin-top: 10px;
}
div.li_content {
    float: left;
    margin-left: 10px;
    width: 80%;
}

.input_type_style {
    display: inline-block;
    height: auto;
    padding: 4px 6px;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: normal;
    color: #555;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle;
    border: 1px solid #a1a1a1;
    -moz-appearance: textfield;
}
.input_type_style:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.input_type_style::-webkit-outer-spin-button,
.input_type_style::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.h_btn {
    border: 1px solid;
    padding: 5px !important;
    border-radius: 5px;
    text-align: center;
    background: #c3c3c34d;
    color: #3e3b3b !important;
}